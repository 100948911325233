<template>
  <div class="mt-5">
    <v-simple-table
      dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="tableSimpleHeader sampleTableBg">No</th>
            <th class="tableSimpleHeader sampleTableBg">Date</th>
            <th class="tableSimpleHeader sampleTableBg">Job Name</th>
            <th class="tableSimpleHeader sampleTableBg">Success</th>
            <th class="tableSimpleHeader sampleTableBg">Failed</th>
            <th class="tableSimpleHeader sampleTableBg">Not Send</th>
            <th class="tableSimpleHeader sampleTableBg">Opened Push</th>
            <th class="tableSimpleHeader sampleTableBg">Opened Inbox</th>
            <th class="tableSimpleHeader sampleTableBg">Total</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item) in reportData">
            <tr v-for="(pushs, index) in item.pushs"
              :key="index">
              <td class="tableVerticalTop"
                v-if="index === 0" 
                :rowspan="item.pushs.length">{{ item.no }}</td>
              <td class="tableSimpleHeader tableVerticalTop"
                 v-if="index === 0" 
                :rowspan="item.pushs.length">{{ item.date | formatDateNotTime }}</td>
              <td>{{ pushs.jobName }}</td>
              <td class="tableSimpleHeader">{{ pushs.success | toNumber }}</td>
              <td class="tableSimpleHeader">{{ pushs.failed | toNumber }}</td>
              <td class="tableSimpleHeader">{{ pushs.notSend | toNumber }}</td>
              <td class="tableSimpleHeader">{{ pushs.openedPush | toNumber }}</td>
              <td class="tableSimpleHeader">{{ pushs.openedInbox | toNumber }}</td>
              <td class="tableSimpleHeader">{{ pushs.total | toNumber }}</td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: 'ReportPushPerformanceOffer',
  props: {
    reportData: {
      type: Array
    },

    loadReport: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataSum: {}
    }
  }
}
</script>